
import { defineComponent, onMounted } from 'vue';
import gsap from 'gsap';

import Card from '@/components/home/Cardrrss.vue';
import Badge from '@/components/home/Badge.vue';
import cards from '@/fixtures/home/cards.json';

export default defineComponent({
  name: 'RRSS',

  components: {
    Card,
    Badge,
  },

  setup() {
    onMounted(() => {
      const tl = gsap.timeline();

      gsap.set('.Home__Cardrrss', {
        opacity: 0,
        y: 200,
      });

      gsap.set('.Home__Badge', {
        opacity: 0,
        rotate: -180,
      });

      tl.to('.Home__shadow', {
        opacity: 1,
        duration: 1,
      });

      tl.to('.Home__shadow', {
        opacity: 0,
        duration: 2,
        onComplete: () => {
          gsap.set('.Home__shadow', { zIndex: -1 });
        },
      });

      tl.to('.Home__Badge', {
        opacity: 1,
        rotate: 0,
        duration: 1,
        delay: -1,
      });

      tl.to('.Home__Cardrrss', {
        opacity: 1,
        y: 0,
        stagger: 0.5,
        ease: 'Power1.inOut',
        delay: -2,
      });
    });

    return { cards };
  },
});
