<template>
  <router-view/>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
</style>
