
import { defineComponent, onMounted } from 'vue';
import gsap from 'gsap';
import App from '@/App.vue';



export default defineComponent({
  components: { App },
  name: 'container',

 

  setup() {
    onMounted(() => {
      const tl = gsap.timeline();

      gsap.set('.container', {
        opacity: 0,
        
      });
      
      
      gsap.set('.title', {
        opacity: 1,
        
      });

      gsap.set('.div1', {
        opacity: 0,
        
      });
      gsap.set('.div3', {
        opacity: 0,
        
      });
      gsap.set('.div1-2', {
        opacity: 0,
        
      });

      gsap.set('.sombra', {
        opacity: 0,
        
      });


      tl.to('.container', {
        opacity: 1,
        duration: 2,
      });
      tl.to('.div1', {
        opacity: 1,
        duration: 2,
        });
      tl.to('.div1-2', {
        opacity: 1,
        duration: 2,
        });
      tl.to('.div3', {
        opacity: 1,
        duration: 2,
        });
      tl.to('.sombra', {
        opacity: 1, 
        duration: 2,
        rotate: -360,
        stagger: 0.5,
        ease: 'Power1.inOut',
        delay: -2,
        });
 
    });

    return ;
  },
});
