
import { defineComponent, onMounted } from 'vue';
import gsap from 'gsap';



export default defineComponent({
  name: 'smials',

 

  setup() {
    onMounted(() => {
      const tl = gsap.timeline();

      gsap.set('.Smials', {
        opacity: 0,
        
      });
      tl.to('.Smials', {
        opacity: 1,
        duration: 3,
      });
      gsap.set('.Smialscolumn', {
        opacity: 0,
        
      });
      gsap.set('.title', {
        opacity: 0,
        
      });
      gsap.set('.sombra', {
        opacity: 0,
        
      });


      tl.to('.title', {
        opacity: 1,
        duration: 2,
      });



      
      tl.to('.Smialscolumn', {
        opacity: 1,
        duration: 2,
        });

      
      tl.to('.sombra', {
        opacity: 1, 
        duration: 2,
        stagger: 0.5,
        ease: 'Power1.inOut',
        delay: -2,
      });
    
      
     
    });

    return ;
  },
});
