
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Cardrrss',

  props: {
    backgroundImage: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    return {
      props,
    };
  },
});
